import React from 'react'

// Gatbsy
import { Link } from 'gatsby'

const Navlink = ({ type, data }) => {
  if (data) {
    const { fields, frontmatter } = data
    const { slug } = fields
    const { title } = frontmatter

    const navLinkClassNamePrefix = type === 'prev' ? 'prev' : 'next'

    return (
      <Link className={`nav-link nav-link--${navLinkClassNamePrefix}`} to={slug}>
        <span>{title}</span>
      </Link>
    )
  }

  return null
}

export default Navlink
