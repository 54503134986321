import React, { Fragment } from 'react'

// Graphql
import { graphql } from 'gatsby'

// Gatsby Plugins
import { MDXRenderer } from 'gatsby-plugin-mdx'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import Comments from '@components/Comments'
import Navlink from '@components/Navlink'
import Tag from '@components/Tag'
import PostInfo from '@components/PostInfo'
import Separator from '@components/Separator'
import SEO from '@components/SEO'

// Utils
import { getReadingTime } from '@utils/getReadingTime'

const PostTemplate = ({ data: { mdx }, pageContext }) => {
  const { id, body, frontmatter, fields } = mdx
  const { readingTime, slug } = fields
  const { next, previous } = pageContext
  const { metaDescription, metaKeywords, title, createdAt, tags, dateTime } = frontmatter

  const formattedReadingTime = getReadingTime(readingTime.minutes)

  return (
    <Fragment>
      <SEO
        slug={slug}
        title={title}
        description={metaDescription}
        keywords={metaKeywords}
        dateTime={dateTime}
        isArticle
      />
      <MainLayout>
        <div className='article'>
          <div className='article__info'>
            <h1 className='article__info-title'>{title}</h1>
            <PostInfo createdAt={createdAt} readingTime={formattedReadingTime} />
            <nav className='article__info-tags'>
              {tags.map((tag, index) => (
                <Tag tag={tag} key={index} />
              ))}
            </nav>
          </div>
          <Separator />
          <div>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          <Separator />
          <Comments identifier={id} title={title} slug={slug} />
          <Separator />
          <div className='article__nav-link-container'>
            <Navlink type='prev' data={previous} />
            <Navlink type='next' data={next} />
          </div>
        </div>
      </MainLayout>
    </Fragment>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query postQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        dateTime: createdAt
        createdAt(formatString: "DD MMMM YYYY", locale: "tr")
        tags
        metaDescription
        metaKeywords
      }
      fields {
        slug
        readingTime {
          minutes
        }
      }
    }
  }
`
