import React, { Fragment } from 'react'

// Components
import { DiscussionEmbed } from 'disqus-react'

const disqusShortName = process.env.DISQUS_SHORT_NAME
const siteURL = process.env.SITE_URL

const Comments = ({ title, identifier, slug }) => {
  const url = siteURL + slug

  const disqusConfig = {
    identifier,
    title,
    url
  }

  return (
    <Fragment>
      <h1 className='title title--mb-0'>Yorumlar</h1>
      <p>Soru, cevap ve destekleriniz için aşağıdan yorum bırakmayı unutmayın.</p>
      <DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
    </Fragment>
  )
}

export default Comments
